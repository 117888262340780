.heading-nw{
font-weight: bold;
font-size: 24px;
color:  #1C75BC;
}
.basic-margin{
    margin-top: 40px;
    margin-left: 85px;
}
.level{
    color:  #1C75BC;
    font-weight: 600;
    
}

.pads{
    
    padding-left: 96px;
    padding-top: 24px;

}

.square{
    width: 100px;
    height: 100px;
    background: whitesmoke;
}

.m30{
    margin-top: 30px;
}
.hw100{
    width: 100px;
    height: 100px;
}
.mr85{
    margin-right: 85px;
}
.red{
color:red !important
}