.displayFlex{
    display: flex;
    justify-content: space-between;    
    margin-bottom: 10px;
    
}
.outerDiv{
    max-width: 508px;
    margin-top: 30px;
    margin-left: 40px;
}

.wid260{
    width: 290px !important;
}
.buttonMargin{

    margin-top: 25px;
    margin-left: 60px;
}

.buttonMargin2{
    margin-top: 25px;
    margin-left: 60px;
    margin-bottom: 40px;
}
.nextButton1{
    border: unset;
    color: #1c75bc;
    background-color: transparent;
    text-decoration: underline;
    border-radius: 4px;
    font-size: 22px;
    font-weight: bold;
    font-family: Nunito;
}
.nextButton1:hover{
    cursor: pointer;
}
.buttionDiv{
    text-align: right;
    padding-top: 20px;
    margin-right: 38px;
}
.imgVerifictaionProfile {   
     width: 100%;
    height: 140px;
    object-fit: contain;
}
.imgVerifictaionAddress {   
    width: 74%;
    height: 140px;
    object-fit: contain;
}

.imgVerifictaionDL {   
    width: 70%;
    height: 270px;
    object-fit: contain;
}
.imgVerifictaionAadhar {   
    width: 44%;
    height: 190px;
    object-fit: contain;
}
.adhaarDiv{
    margin-top: 70px;
    margin-bottom: 62px;
    margin-right: 19px;
    display: flex;
}
.verificationStatusInHeader{
    display: flex;
    align-items: center;
    margin: -5px;
    margin-left: 18px;
}
.endPage{
    height: 74px;
    position: relative;
    /* background: aqua; */
    top: 300px;
}
.profileReason{
    margin-left: 43px;
    margin-bottom: 24px;
}