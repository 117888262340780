.PrimaryColorButton{
    background-color: #2475bc !important;
  }
.ShiftPlanContainer{
    padding: 40px;
}
.ShiftPlanHeadingContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px !important;
}
.shiftPlanHeading{
    font-family: Nunito;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
}
.blueContainedButton{
    background-color: #1c75c0;
    text-transform: uppercase;
    color: white;
    padding: 12px;
    border: none;
    &:hover{
        cursor: pointer;
        transform: translateY(-2px);
    }
    
    &:focus{
        outline: unset;
    }
    &:active{
        transform: translateY(-1px);

    }

}
.blueOutlinedButton{
    color: #1c75c0;
    text-transform: uppercase;
    border: 1px solid #1c75c0;
    padding: 12px;
    font-weight: bold;
    &:hover{
        cursor: pointer;
        transform: translateY(-2px);
    }
    
    &:focus{
        outline: unset;
    }
    &:active{
        transform: translateY(-1px);

    }

}
.timeLineContainer{
    display: flex;
    margin-left: 40px;
}
.timeContainer{
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.timeStick{
    border-left: 1px solid #ffffff;
    height: 12px;
}
.timeTextLarge{
    // font-family: OpenSans;
    font-size: 18px;
    font-weight: 600;
    margin-left: -50%;
}
.timeTextSmall{
    // font-family: OpenSans;
    font-size: 10px;
    margin-left: -3px;
    font-weight: 600;
}
.timelineColumn{
    vertical-align: bottom;
    padding-bottom: 0;
}
.ShiftPlanTableContainer{
    margin-top: 20px;
}
.paperResponse{
    width: 650px;
    border-radius: 0 !important;
    max-width: 800px !important;
  }
.noRecord{
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 50px;
}
.datePicker{
    width: 200px;
    div {
      border-radius: 0 !important;  
      padding: 10px !important;
    }
    input {
      padding: 8px ;
    }
  }
  .datePicker .MuiOutlinedInput-root {
    border-radius: 0 !important;
  }

//filters

.FilterContainer{
    max-height: 100vh;
    overflow: auto;
}

.FilterHeadingContainer{
    display:flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: end;
}
.flexBaseLine{
    display: flex;
    align-items: center;
}
.filterHeading{
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.04;
    letter-spacing: normal;
    text-align: left;
    color: #1c75c0;
}
.filterCloseArrow{
    margin-right: 20px !important;
    margin-left: 10px !important;
    border-radius: 20px !important;
    padding: 5px !important;
}
.filterHeadingDivider{
    width: 100%;
    border-top: 1px solid #d5d5d5;
}
.resetFiltersButton{
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #707070 !important;
    text-decoration: underline !important;
    margin-right: 24px !important;
    text-transform: capitalize !important;
    &:hover{
        background-color: unset !important;
        text-decoration: underline;
    }
}
.filterButtonDividerContainer{
 position: absolute;
  bottom: 0px;
  background: white;
  padding-bottom: 25px;
}
.filtersContainer{
    padding: 40px 30px;
}
.singleFilterContainer{
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px !important;
    align-items: center;
    padding: 0 20px;
}
.filterTitle{
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #303030;
}

.searchButtonDiv{
    display: flex;
    margin-left: -140px;
    z-index: 100;
    .closeButton{
      margin-right: 8px;
      padding: 0 4px;
      height: 30px;
      margin-top: 3px;
    }
    .noVisibility{
      visibility: hidden;
    }
    .searchButton{
      border: 0;
      background-color: #1c75bc;
      color: white;
      width: 100px;
      font-size: 17px;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      height: 35px;
      &:hover{
        cursor: pointer;
      }
      &:focus{
        outline: 0;
      }
    }
  }


//shift upload modal
.paper{
    width: 600px;
    border-radius: 0 !important;
    max-width: 800px !important;

}

.uploadButton{
    color:#1c75bc;
    border-color: #1c75bc;
    margin-top: 10px; 
}

.downloadTemplate{
    margin-right: 50px;
    text-decoration: unset;
    span{
      font-size: 17px;
      color: #1c75bc;
      text-decoration: underline;
      margin-right: 10px;
    }
  }
.fileName{
    max-width: 540px;
    background-color: #1C75BC;
    line-height: 19px;
    border-radius: 14px;
    padding: 7px 10px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
}
.effectiveDateHeading{
    font-size: 18px;
    font-weight: 600;
    color: #455a64;
    padding-bottom: 24px;
}
.editableEffectiveDate{
    justify-content: flex-end;
    align-items: baseline;
}
.editButton{
    background-color: unset ;
    border: none ;
    color:#1c75bc ;
    text-decoration: underline ;
    text-transform: capitalize ;
    font-weight: 600;
    margin-left: 24px;
    // font-size: 16px;
    float: right;
    &:focus{
        outline: none;
    }
    &:hover{
        cursor: pointer;
    }
    
}


// timelineColumn css
.shiftTimeContainer{
    display: flex;
    border-radius: 12px;
    background-color: rgba(28, 117, 192, 0.14);
    height: 32px;
    font-size: 14px;

}
.divValuesContainer{
    display: flex;
    align-items: center;
    padding: 0 10px;
}
.shiftTimeContainerBlank{
    // padding: 10px;
    border-radius: 12px;
    height: 32px;

}
.partnerIcon{
    height: 24px; 
    margin-right: 8px;
}
.flipkartContainer{
}
.swiggyContainer{
    padding: 10px 14.1px 10px 12px;
    border-radius: 12px;
    background-color: rgba(28, 117, 192, 0.14);
    height: 52px;
}