.datePicker{
  width: 180px;
  padding: 0 !important;
  div {
    border-radius: 0 !important;  
  }
  input {
    padding: 8px ;
  }
}
.datePicker:first-child{
  margin-right: 20px;
}
.datePicker .MuiOutlinedInput-root {
  border-radius: 0 !important;
}
.HoldSwitchContainer{
  justify-content: center;
  .holdButton{
    &:hover{
      cursor: pointer;
    }
    border: none;
    padding: .5rem 1rem;
    border-radius: .5rem;
    color: white;
    background-color: green;
    // font-size: 1.5rem;
  }
  .onHold{
    background-color: red;
  }
}
.paper{
  width: 779px;
  height: 423px;
}
.paperReassign{
  width: 400px;
  height: 250px;
}
.reassignButtonContainer{
  padding: 10px;
  justify-content: center;
  button{
    border: none;
    background-color: #1c75bc;
    padding: 16px 70px;
    border-radius: 5px;
    color: white;
    &:hover{
      cursor: pointer;
    }
    &:disabled{
      filter: brightness(65%);
    }
  }
}
.StatusUpdateContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10%;
}
.StatusUpdateButtonContainer{
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  padding: 10px 12% 50px;
  button{
    border: none;
    background-color: #1c75bc;
    padding: 16px 70px;
    border-radius: 5px;
    color: white;
    &:hover{
      cursor: pointer;
    }
    &:disabled{
      filter: brightness(65%);
    }
  }
}
.reassignButton{
  border: none;
  padding: 7px 18px;
  font-size: 16px;
  background-color: #1c75bc;
  color: white;
  &:hover{
    cursor: pointer;
  }
  &:disabled{
    background-color: #707070;
  }
}
.StatusContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  span{
    min-width: 100px;
    max-width: 190px;
    margin-right: 10px;
  }
  button{
    border: none;
    background-color: transparent;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    padding: 3px;
    &:hover{
      background-color: #eee;

    }
  }
}
.filterButton{
  border: 1px solid #cccccc;
  border-radius: none;
  background-color: none;
  color: #5F5F5F;
  font-size: 20px;
  padding: .5rem 1.3rem;
  display: flex;
  align-items: center;
  img{
    margin-right: 5px;
  }
  &:hover{
    cursor: pointer;
  }
}
.noRecord{
  font-family: Nunito;
  font-size: 32px;
  font-weight: 600;
  color: #707070;
  margin: 150px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  img{
    margin-bottom: 30px;
  }
}
.searchButtonDiv{
  display: flex;
  margin-left: -140px;
  z-index: 100;
  margin-right: 30px;
  .closeButton{
    margin-right: 8px;
    padding: 0 4px;
    height: 30px;
    margin-top: 3px;
  }
  .noVisibility{
    visibility: hidden;
  }
  .searchButton{
    border: 0;
    background-color: #1c75bc;
    color: white;
    width: 100px;
    font-size: 17px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    height: 35px;
    &:hover{
      cursor: pointer;
    }
    &:focus{
      outline: 0;
    }
  }
}