.root {
  font-family: Nunito, serif;
  box-sizing: border-box;
  text-align: center;
  padding: 40px;
}
.heading{
  color: #1c75bc;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 40px !important;
}
.formParent{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.form{
  display: flex;
  flex-direction: column;
  width: 40%;
  text-align: center;
}
.marginBottom24{
  margin-bottom: 24px !important;
  
}
.changePasswordButton{
  background-color:#1c75bc !important;
  color: #ffffff !important;
}
.changePasswordButton:hover{
  background-color:#1463a3 !important;
}
.TextField{
  background-color: #ffffff;
}
.borderColor .Mui-focused{

  outline-color: green !important;
}
.formElement{

}