.page {
  font-family: Nunito, serif;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 40px;
  height: 100vh;
}

.artImage {
  flex: 0 0 auto;
}

.loginSection {
  width: 560px;
  margin-left: 36px;
  padding: 26px 52px;
  border-radius: 29px;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
}

.logoHolder {
  text-align: right;
}

.loginForm {
  margin-top: -20px;
  
  .heading {
    font-size: 32px;
    font-weight: bold;
    line-height: 24px;
    color: #1c75bc;
    margin-bottom: 32px;
  }

  .form {
    .formElement {
      label, input {
        display: block;
        width: 100%;
      }

      label {
        font-size: 18px;
        font-weight: bold;
        color: #455a64;
        margin-top: 22px;
        margin-bottom: 16px;
      }
      
      input {
        height: 60px;
        padding: 18px 24px;
        border-radius: 9px;
        border: 1px solid #ccc;
        background-color: #fff;
        box-sizing: border-box;
        font-size: 18px;

        &::placeholder {
          color: #ccc;
        }
      }
    }

    .loginButtonHolder {
      text-align: center;

      .loginButton {
        margin: 67px 0 24px 0;
        width: 358px;
        height: 59px;
        border: 1px;
        border-radius: 9px;
        background-color: #1c75bc;
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        &:hover{
          cursor: pointer;
        }
        &:disabled{
          background-color: #cccccc;
        }
      }
    }
    .redirectButton{
      font-size: 17px;
      font-weight: bold;
      text-decoration: underline;
      color: #1c75bc;
      border: unset;
      background-color: unset;
      font-family: Nunito;
    }
  }
}
.navBarStyle{
  height: 80px ;
  background-color: #1c75bc;
  text-align: center;
}
.successContainer{
  border-radius: 31px;
  border: solid 1px #d2d2d2;
  padding: 60px;
  margin: 90px  auto !important;
  background-color: #fff;
  display: flex;
  width: 820px;
  flex-direction: column;
  align-items: center;
  .heading{
    font-family: Nunito;
    font-size: 36px;
    font-weight: bold;
    color: #1c75bc;
    margin: 50px 0 24px 0;
  }
  .content{
    font-family: Nunito;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    width: 400px;
  }
}
.showPassword{
  display: flex;
  button{
    margin-left: -55px;
    height: 50px;
    margin-top: 5px;
  }
}
.tooltipArrow{
  // background-color: red;
  color: white;
}
.tooltip{
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.23);
  color: #707070;
  background-color: #ffffff;
  padding: 0;
}
.tooltipContainer{
  font-size: 12px;
  .passwordText{
    padding: 25px 25px 0 25px ;

  }
  ul{
    padding: 10px 30px;
  }
  li{
    padding: 5px; 
  }
  .passwordPolicy{
    ul{
      padding: 5px 45px;
    }
    li{
      padding: 5px;
    }

  }
}

.verifyButton{
  margin-left: -170px;
  font-family: Nunito;
  font-size: 18px;
  font-weight: bold;
  border: 0;
  background-color: transparent;
  color: #1c75bc;
  height: min-content;
  margin-top: 18px;
  &:disabled{
    color: #cccccc;
  }
}
.userMessageContainer{
  display: flex;
  margin-top: 8px;
}
.loginButton{
  margin: 36px 179px 0 180px;
  padding: 12px 49px 11px;
  border-radius: 9px;
  background-color: #1c75bc;
  color: #ffffff;
  font-size: 19px;
  text-decoration: unset;
  font-weight: bold;
}