.crossIcon{
    position: relative;
    left: 275px;
    bottom: 10px;
}
.availableBal{
    background: #F3FAFF;
    box-shadow: 0px 0px 2px rgb(0 0 0 / 25%);
    border-radius: 5px;
    font-size: 20px;
    padding: 10px;
    font-weight: 500;
    margin-top: 10px;
}
.bal{
    color: #1C75BC;
}
.normal{
    font-size: 18px;
    margin: 8px;
    font-weight: 400;
}
.normal2{
    font-size: 18px;
    margin: 8px;
    font-weight: 500;
}
.green{
color: #00BA13;

}
.red{
    
    color:#FF0000;
}