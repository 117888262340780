.editButton{
  float: right;
  padding: 12px 30px;
  font-size: 19px;
  border: 1px solid #1c75bc;
  border-radius: 4px;
  color: #1c75bc;
  background-color: transparent;
  margin-bottom: -35px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Nunito';
  &:hover{
    cursor: pointer;
  }
}