.pnMain{
    padding:7px 5px;
    div{
    margin: 10px 30px;
}
}


.pennyName{ 
    margin-left: 0px !important;
    font-weight: 500;
    font-size: 30px;
}

.pennyPhone{
    // margin-left: 50%;
    margin-right: 0px !important;
    padding-top: 10px !important;
    font-size: 22px;
}
.pennyAcName{
    font-weight: 600;
    font-size: 22px;
}
.pennyBox{
    padding: 0px;
    background-color:#F3FAFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
    // text-align: center;
}

.pennyIcons{  
      position: relative;
    bottom: -4px;
    width: 6%;
}
.pennyMiddleSection{
    padding:35px 35px 35px 35px;
}
.pennyMiddleInfo{
    // border:1px solid red;
    display: flex;
    margin: 2% 0;
    font-size: 18px;
    font-weight: 500;
    // justify-content: space-between;
}
.pennyLowerCapitalize{
    text-transform: lowercase;
}
.pennyLowerCapitalize::first-letter{
    text-transform: capitalize;
}
.pennyMiddleLeftInfo{
    max-width: 200px;
    display: inline-block;
}
.pennyMiddleRightInfo{
    max-width: 200px;
    display: inline-block;
    position: relative;
    top: -24px;
}
.pennyButton{
    width: 210px !important;
    height: 50px !important;
    font-size: 18px !important;
    border-radius: 5px !important;
    text-transform: capitalize !important;
}
.pennyButton2{
    width: 200px;
    // margin: 15px 10px 10px 35px !important;
    color: #1C75BC;
    height: 40px;

}

.pennyBoxOuter{
    // padding: 40px;
    background-color: white;
    min-height: 845px;
    width:535px;
    // border: none;
}
.pannyStatusDiv{
    // margin: 8px;
    // width: 300px;
    display: flex;
    justify-content: start;
  }


  .width54Per{
    width: 54%;
  }
.pennyTable{
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    background-color: #F3FAFF;
    border-radius: 5px;
}