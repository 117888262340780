.page {
  font-family: Nunito, serif;
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 40px;
  height: 100vh;
}

.artImage {
  flex: 0 0 auto;
}

.loginSection {
  width: 560px;
  height: 664px;
  margin-left: 36px;
  padding: 26px 52px;
  border-radius: 29px;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
}

.logoHolder {
  text-align: right;
}

.loginForm {
  margin-top: -20px;
  
  .heading {
    font-size: 32px;
    font-weight: bold;
    line-height: 24px;
    color: #1c75bc;
    margin-bottom: 32px;
  }

  .form {
    .formElement {
      label, input {
        display: block;
        width: 100%;
      }

      label {
        font-size: 18px;
        font-weight: bold;
        color: #455a64;
        margin-top: 22px;
        margin-bottom: 16px;
      }
      
      input {
        height: 60px;
        padding: 18px 24px;
        border-radius: 9px;
        border: 1px solid #ccc;
        background-color: #fff;
        box-sizing: border-box;
        font-size: 18px;

        &::placeholder {
          color: #ccc;
        }
      }
    }

    .loginButtonHolder {
      text-align: center;

      .loginButton {
        margin: 38px 0 24px 0;
        width: 358px;
        height: 59px;
        border: 1px;
        border-radius: 9px;
        background-color: #1c75bc;
        font-size: 24px;
        font-weight: bold;
        color: #fff;
        &:hover{
          cursor: pointer;
        }
        &:disabled{
          background-color: #cccccc;
        }
      }
    }
    .redirectButton{
      font-size: 17px;
      font-weight: bold;
      text-decoration: underline;
      color: #1c75bc;
      border: unset;
      background-color: unset;
      font-family: Nunito;
    }
  }
}
.forgotPassword{
  text-align: end;
  margin-top: 16px;
  button{
    font-family: Nunito;
    font-weight: bold;
    font-size: 16px;
    color: #1c75bc;
    background-color: unset;
    border: 0;
    text-decoration: underline;
    &:focus {
      outline: unset;
    }
    &:hover {
      cursor: pointer;
    }
  }
  
    
}
.paperForgotPassword{
  width: 800px;
}
.backButton{
  text-align: left;
  margin-bottom: -45px;
  button{
    background-color: #cccccc;
  }

}
.forgotPasswordContainer{
  padding: 60px 120px 20px 120px;
  display: flex;
  flex-direction: column;
  .heading {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 32px;
  }
  input {
    height: 60px;
    padding: 18px 24px;
    border-radius: 9px;
    border: 1px solid #ccc;
    background-color: #fff;
    box-sizing: border-box;
    font-size: 18px;

    &::placeholder {
      color: #ccc;
    }
    &:focus{
      outline: none;
      border: 1px solid #888888;
      border-radius: 9px;
    }
  }
}