.label {
    color: black;
    font-size: 18px;
    padding-right: 60px;
    margin-bottom: 4px;
    font-weight: 600;
}
.box-info {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    height: max-content;
    text-align: left;
    font-size: medium;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;

}
.box {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    height: 19rem;
}
.paddingTop20 {
    padding-top: 20px;
    padding-left: 350px;
    padding-right: 350px;
}
@media only screen and (max-device-width: 480px) {
    .paddingTop20 {
        
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;

    }
    .closeButton{
        color: rgb(245, 13, 13)!important;
        background-color: #69696966!important;
        border-radius: 10%!important;
       position: relative;
       left: 206px;
    }
    .box {
        /* border: 3px solid #3f51b5a6; */
        height: 30rem !important;
    }
    .mt-24 {
        margin-top: 100px !important;
    }
    .label {
        padding-right: 20px !important;
       
    }
    .width-250{
        width: 190px !important;
    }
    .margin-15{
        margin-top: 60px !important;
    }
    .errTxt {
        position: relative;
        top: 110px;
    }
}
.sndOTP {
    padding-top: 47px;
    /* padding-left: 24px; */
}
.heading {
    text-align: center;
    background: #70708B;
    color: white;
    padding-bottom: 10px;
    padding-top: 7px;
    margin-bottom: 0;
}

.errTxt {
    text-align: center;
    background: #b14949;
    color: white;
    padding-bottom: 10px;
    padding-top: 7px;
    margin-top: 74px;
}
.varified {
    text-align: center;
    background: #7db36e;
    color: white;
    padding-bottom: 10px;
    padding-top: 7px;

}
.info {
    color: black;
    font-size: 17px;
    padding-right: 8px;
    font-weight: 600;
    margin-bottom: 0;
    margin-left: 6px;
    margin-top: 0;
}
.mt-24 {
    margin-top: 24px;
}
.width-250{
    width: 250px;
}
.margin-15{
    margin-top: 15px;
}
.model{
    position: 'absolute';
    width: 200;
    height:300;
    background-color:#7db36e ;
    border: '2px solid #000';
    /* boxShadow: theme.shadows[5]; */
    padding: 2, 4, 3,
}
 .stickHeader{
     position: sticky;
     /* display: inline-block; */
     /* right: 0;
     height: 0; */
     top: 0;
     z-index: 1;
     
    }
    .closeButton{
     color: rgb(245, 13, 13)!important;
     background-color: #69696966!important;
     border-radius: 10%!important;
    position: relative;
    left: 206px;
 }
 .header-custom{
     background-color: #1c75bc;
     margin-top: 0px;
     margin-bottom: 0px;
 }

 .icon{
    margin-left: 26px;
    margin-top: 5px;
 }

 .thumb{
    color: #1c75bc;
    margin-top: 9px;
    width: 100% !important;
    height: 95px !important;
 }
 .thanksText{
    font-size: 20px;
    padding-left: 10px;
    padding-top: 0px;
    text-align: center;
    font-weight: 700;

 }
