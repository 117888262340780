.datePicker{
  width: 180px;
  padding: 0 !important;
  div {
    border-radius: 0 !important;  
  }
  input {
    padding: 8px ;
  }
}
.datePicker:first-child{
  margin-right: 20px;
}
.datePicker .MuiOutlinedInput-root {
  border-radius: 0 !important;
}
.filtersContainer{
  justify-content: space-evenly;
  align-items: flex-start;
  >div{
    margin: 30px;
  }
}
.buttonGroup{
  button{
      border-radius: 0;
      border: 1px solid #A3A3A3;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #2E2E2E;
  }
  .buttonSelected{
      background-color: #1c75c0;
      color: #ffffff;
  }
}
.fileName{
  background-color: #1c75bc;
  margin-left: 10px;
  color: white;
  padding: 5px 10px;
  border-radius: 18px;
}
.outlinedClients{
  padding: 7px 12px;
  border-radius: 0;
}

.HoldSwitchContainer{
  justify-content: center;
  .holdButton{
    &:hover{
      cursor: pointer;
    }
    border: none;
    padding: .5rem 1rem;
    border-radius: .5rem;
    color: white;
    background-color: green;
    // font-size: 1.5rem;
  }
  .onHold{
    background-color: red;
  }
}
.noRecord{
  font-family: Nunito;
  font-size: 32px;
  font-weight: 600;
  color: #707070;
  margin: 150px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  img{
    margin-bottom: 30px;
  }
}
.searchButtonDiv{
  display: flex;
  margin-left: -140px;
  z-index: 100;
  margin-right: 30px;
  .closeButton{
    margin-right: 8px;
    padding: 0 4px;
    height: 30px;
    margin-top: 3px;
  }
  .noVisibility{
    visibility: hidden;
  }
  .searchButton{
    border: 0;
    background-color: #1c75bc;
    color: white;
    width: 100px;
    font-size: 17px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    height: 35px;
    &:hover{
      cursor: pointer;
    }
    &:focus{
      outline: 0;
    }
  }
}