.fromTo{
    font-weight: 600;
    margin-right: 15px;
    margin-left: 11px;
    margin-top: 15px;
}
.DateRange{
    font-weight: 600;
    font-size: larger;
    margin-right: 15px;
    /* margin-left: 11px; */
    /* display:  inline-flex; */
    /* margin-top: 10px; */
}
.fix-width230{
    width: 230px;
}
.inline{
    display: inline-block;
    border: 2px ridge black;
    margin: 10px;
    font-size: 20px;
    color: #1c75bc;
    padding: 7px;

}
.marginLeft{
    margin-left: 166px !important;
    font-weight: 600;
}
.font14{
    font-size:16px !important
}
.inline2{
    display: inline-block;
    /* border: 2px ridge black; */
    margin: 5px;
    font-size: 20px;
    /* color: #1c75bc; */
    padding: 7px;

}