.container{
  padding: 30px;
  color: #333333;
  button{
    &:hover{
      cursor: pointer;
    }
    &:focus{
      outline: unset;
    }
  }

}
.sectionContainer{
  padding: 30px;
  background-color: white;
  border-radius: 5px;
  &:first-child{
    margin-bottom: 25px;
  }
}
.rightBorder{
  border-right: 1px solid #707070;
}
.paddingleft16{
  padding-left: 16px;
}
.displayRow{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.displayColumn{
  display: flex;
  flex-direction: column;
}
.imageContainer{
  padding-left: 30px;
}
.imageInnerContainer{
  padding: 30px;
  background-color: white;
  border-radius: 5px;
}
.headingText{
  font-family: Nunito;
  font-size: 22px;
  font-weight: bold;
}
.titleText{
  font-family: Nunito;
  font-size: 24px;
  font-weight: bold;
}
.subHeadingText{
  font-family: Nunito;
  font-size: 20px;
  font-weight: bold;
}
.subHeadingTextSemiBold{
  font-family: Nunito;
  font-size: 20px;
  font-weight: 600;
}
.mismatchConfirmed{
  border: 1px solid #1c75bc;
  color:  #1c75bc;
  border-radius: 15px;
  padding : 2px 8px;
  margin-right: 8px;
  background-color: rgba(28, 117, 188, 0.13);
  font-size: 14px;
}
.accountMismatch{
  border: 1px solid #d9534f;
  color:  #d9534f;
  padding : 2px 8px;
  border-radius: 15px;
  background-color: rgba(251, 135, 91, 0.4);
  font-size: 14px;
}
.accountMismatchText{
  color: #d9534f;
  font-size: 12px;
}
.EditButton{
  border: 1px solid #1c75bc;
  color: #1c75bc;
  background-color: transparent;
  height: 28px;
  width: 80px;
  border-radius: 5px;
}
.GreyButton{
  border: 1px solid #424242;
  color: #9b9b9b;
  background-color: transparent;
  height: 28px;
  width: 80px;
  border-radius: 5px;
  cursor: none;
}
.infoTitleText{
  font-family: Nunito;
  font-size: 14px;
  font-weight: bold;
}
.infoValueText{
  font-family: Nunito;
  font-size: 14px;
  font-weight: normal;
}
.imageMagnifier{
  background-color: #707070;
  margin-top: 30px !important;
  height: 600px;
  overflow: hidden;
  div:first-child{
    top: 215px;
    right: 75px;
  }
}
.detailsMismatch{
  span{
    font-family: Nunito;
    font-size: 18px;
    font-weight: 600;
    padding: 0;
  }
}
.detailsContainer{
  border: 1px solid #eeeeee;
  border-radius: 8px;
  padding: 25px;
  margin-bottom: 14px !important;

}
.mismatchButton{
  border: unset;
  background-color: #1c75bc;
  color: white;
  width: 200px;
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 20px;
  &:active{
    background-color: #165e99;
  }
  &:disabled{
    background-color: #707070;
    color: #333333;
  }
  
}
.inputError{
  border-color: red;
  &:focus{
    border-color: red;
    outline: red;
  }
}
.inputNoError{
  &:focus{
    outline: black;
  }
}
.buttonContainer{
  flex-direction: row-reverse !important;
}
.nextRecordButton{
  border: unset;
  color: #1c75bc;
  background-color: transparent;
  text-decoration: underline;
  border-radius: 4px;
  font-size: 22px;
  font-weight: bold;
  font-family: Nunito;
  &:active{
    color: #165e99;
  }
  &:disabled{
    color: #707070
  }
}