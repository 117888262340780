.datePicker{
  width: 180px;
  padding: 0 !important;
  div {
    border-radius: 0 !important;  
  }
  input {
    padding: 8px ;
  }
}
.datePicker:first-child{
  margin-right: 20px;
}
.datePicker .MuiOutlinedInput-root {
  border-radius: 0 !important;
}
.uplaodHubButton{
  border-radius: 8px;
  color: white;
  background-color: #1c75bc;
  padding: 8px 12px;
  font-weight: 500;
  border: 0;
  font-size: 17px;
  height: 35px;
  min-width: max-content;
}
.downloadExcel{
  height: 20px;
  line-height: 1;
  border-radius: 8px;
  min-width: max-content;
  background-color: #1c75bc;
  padding: 8px 12px;
  color: white !important;
  text-decoration: unset;
  font-weight: 500;
  font-size: 17px;
}
.noRecord{
  font-family: Nunito;
  font-size: 32px;
  font-weight: 600;
  color: #707070;
  margin: 150px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  img{
    margin-bottom: 30px;
  }
}
.searchButtonDiv{
  display: flex;
  margin-left: -140px;
  z-index: 100;
  margin-right: 30px;
  .closeButton{
    margin-right: 8px;
    padding: 0 4px;
    height: 30px;
    margin-top: 3px;
  }
  .noVisibility{
    visibility: hidden;
  }
  .searchButton{
    border: 0;
    background-color: #1c75bc;
    color: white;
    width: 100px;
    font-size: 17px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    height: 35px;
    &:hover{
      cursor: pointer;
    }
    &:focus{
      outline: 0;
    }
  }
}

