.text-box{
    border: 1px solid black;
    border-radius: 5px;
    background: #f1f1f1d6;
    padding: 15px;
}
.selected-worker{
    font-size: 15px;
    font-weight: 300;
    color: green;
    padding-left: 7px;
}