.outlinedButton{
  color: white!important;
  background-color: #1c75bc !important;
  border-color: #1c75bc !important;
  margin-left: 20px !important;
  border-radius: 0 !important;
  // text-transform: unset !important;
}
.paper{
  width: 600px;
  border-radius: 0 !important;
  max-width: 800px !important;
}
.paperReactivation{
  width: 500px;
  border-radius: 0 !important;
  max-width: 800px !important;
}
.paperResponse{
  width: 650px;
  border-radius: 0 !important;
  max-width: 800px !important;
}
.paperCriminalRecord{
  width: 650px;
  border-radius: 0 !important;
  max-width: 800px !important;
}
.mainRecord{
  font-size: 18px;
  font-weight: bold;
  margin: 33px 0 28px 0;
}
.criminalCheckBox{
  display: flex;
  align-items: center;
  margin-left: auto;
  div{
    border: 3px solid #fe7976 !important;
    height: 12px;
    width: 12px;
    background-color: #fcd2d1 ;
    margin-right: 10px;
  }
  span{
    font-size: 14px;
    font-weight: 600;
  }
}
.datePicker{
  margin-top: 10px;
  padding: 0 !important;
  div {
    border-radius: 0 !important;  
  }
  input {
    padding: 8px ;
  }
}
.datePicker .MuiOutlinedInput-root {
  border-radius: 0 !important;
}
.activateButton{
  font-size: 16px;
  font-weight: bold;
  color: #1c75bc;
  border: none;
  background-color: unset;
  text-decoration: underline;
  margin-top: 8px;
  width: fit-content;
  &:hover{
    cursor: pointer;
  }
  &:focus{
    outline:none;
  }
}
.criminalCheckBoxChecked{
  color: #fe7976;
}
.downloadTemplate{
  margin-left: 40px;
  text-decoration: unset;
  span{
    font-size: 17px;
    color: #1c75bc;
    text-decoration: underline;
    margin-right: 10px;
  }
}

.blueOutlinedButton{
  color: #1c75c0;
  text-transform: uppercase;
  border: 1px solid #1c75c0;
  padding: 12px;
  font-weight: bold;
  &:hover{
      cursor: pointer;
      transform: translateY(-2px);
  }
  
  &:focus{
      outline: unset;
  }
  &:active{
      transform: translateY(-1px);

  }

}
.greyOutlinedButton{
  color: #7a7a7a;
  text-transform: uppercase;
  border: 1px solid #808080;
  padding: 12px;
  font-weight: bold;


}
.filterCloseArrow{
  margin-right: 20px !important;
  margin-left: 10px !important;
  border-radius: 20px !important;
  padding: 5px !important;
}
.FilterHeadingContainer{
  display:flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: end;
}
.filterHeading{
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.04;
  letter-spacing: normal;
  text-align: left;
  color: #1c75c0;
}
.resetFiltersButton{
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #707070 !important;
  text-decoration: underline !important;
  margin-right: 24px !important;
  text-transform: capitalize !important;
  &:hover{
      background-color: unset !important;
      text-decoration: underline;
  }
}
.filterHeadingDivider{
  width: 100%;
  border-top: 1px solid #d5d5d5;
}
.filtersContainer{
  padding: 40px 30px;
}
.singleFilterContainer{
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px !important;
  align-items: center;
  padding: 0 20px;
}
.filterTitle{
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #303030;
}
.uploadButton{
  color:#1c75bc;
  border-color: #1c75bc;
  margin-top: 10px; 
}
.casperDownloadTemplate{
  text-decoration: unset;
  span{
    font-size: 17px;
    color: #1c75bc;
    text-decoration: underline;
    margin-right: 10px;
  }
}
.noRecord{
  font-family: Nunito;
  font-size: 32px;
  font-weight: 600;
  color: #707070;
  margin: 150px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  img{
    margin-bottom: 30px;
  }
}
.searchButtonDiv{
  display: flex;
  margin-left: -140px;
  z-index: 100;
  .closeButton{
    margin-right: 8px;
    padding: 0 4px;
    height: 30px;
    margin-top: 3px;
  }
  .noVisibility{
    visibility: hidden;
  }
  .searchButton{
    border: 0;
    background-color: #1c75bc;
    color: white;
    width: 100px;
    font-size: 17px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    height: 35px;
    &:hover{
      cursor: pointer;
    }
    &:focus{
      outline: 0;
    }
  }
}
.fileName{
  margin-left: 24px;
  span {
    font-weight: 700;
  }
}
.responseHeading{
  color: #35343d;
  margin-left: 24px;
  font-size: 20px;
  font-weight: bold;

}
.workerName{
  background-color: unset;
  border: none;
  font-size: 14px;
  color: #1c75bc;
  padding: 0;
  text-decoration: underline;
  &:focus{
    outline: none;
  }
  &:hover{
    cursor: pointer;
  }
}
.summaryText{
  color:#1c75bc;
  font-weight: bold;
  font-size: 18px;
}
table { 
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: separate;
  border-left: 0;
  border-radius: 4px;
  border-spacing: 0px;
}
thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  border-collapse: separate;
}
tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
tr td:last-child{
  text-align: right;
}
th, td {
    padding: 12px ; 
    text-align: left;
    vertical-align: top;
    border-left: 1px solid #ddd;    
}
td {
    border-top: 1px solid #ddd;    
}
thead:first-child tr:first-child th:first-child, tbody:first-child tr:first-child td:first-child {
    border-radius: 4px 0 0 0;
}
thead:last-child tr:last-child th:first-child, tbody:last-child tr:last-child td:first-child {
    border-radius: 0 0 0 4px;
}
.criminalRecordContainer{
  display: flex;
  flex-direction: column;
  text-align: center;
  span {
    margin-top: 30px;
    font-size: 21px;
    font-weight: 600;
    padding: 20px 70px 0 70px;
  }
}
.criminalRecordActions{
  .yes{
    height: 40px;
    color: #1c75bc !important;
    border-color: #1c75bc;
    width: 100px;
  }
  .no{
    height: 40px;
    background-color: #1c75bc !important;
    color: #ffffff;
    width: 100px;
    margin-right: 40px;
  }
}
.agreementDownloadButton{
  border: unset;
  background-color: unset;
  font-size: 18px;
  color: #1c75bc;
  text-decoration: underline;
  &:hover{
    cursor: pointer;
  }
  &:focus{
    outline: unset;
  }
  &:active{
    color: #11446e;
    
  }
}
.waitText{
  font-family: Nunito;
  font-size: 18px;
  text-align: center;
  color: #1c75bc;
  width: 55%;
  font-weight: 600;
  margin: auto !important;
}
.DownloadButtonContainer{
  margin: 30px auto !important;
  width: fit-content;
}
.actionSelect{
  padding: 9px 35px 9px 9px !important; 
}
.itemRowReverse{
  display: flex;
  flex-direction: row-reverse;
  align-items: baseline;

}
.casperInstruction{
  color: #808080;
  font-weight: 600;
  font-style: italic;
  font-size: 14px;
}