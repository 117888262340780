table{
    text-align: left;
    padding: 0%;
    border-collapse: collapse;
    border-spacing: 0;
    border:none;
    font-weight: 400;
    font-size: 18px;
    // border: 2px solid green;
    td{
        border:none;
        text-align: left !important;
        // font-weight: 500;
    }

    div{
        text-align: left;
        // border:1px solid red;
        // vertical-align: left;
    }
}
// table, td{
    //     // border: none;
    // }