.outlinedButton{
  color: white!important;
  background-color: #1c75bc !important;
  border-color: #1c75bc !important;
  margin-left: 20px !important;
  border-radius: 0 !important;
  // text-transform: unset !important;
}
.pannyStatusDiv{
  margin: 8px;
  // width: 300px;
  display: flex;
  justify-content: start;
}
.paper{
  width: 600px;
  border-radius: 0 !important;
  max-width: 800px !important;
}
.paperReactivation{
  width: 500px;
  border-radius: 0 !important;
  max-width: 800px !important;
}
.paperResponse{
  width: 650px;
  border-radius: 0 !important;
  max-width: 800px !important;
}
.invoiceGeneratedDropdown{
  width: 150px;
  margin-left: 60px !important;
}
.paperCriminalRecord{
  width: 650px;
  border-radius: 0 !important;
  max-width: 800px !important;
}
.mainRecord{
  font-size: 18px;
  font-weight: bold;
  margin: 33px 0 28px 0;
}
.criminalCheckBox{
  display: flex;
  align-items: center;
  margin-left: auto;
  div{
    border: 3px solid #fe7976 !important;
    height: 12px;
    width: 12px;
    background-color: #fcd2d1 ;
    margin-right: 10px;
  }
  span{
    font-size: 14px;
    font-weight: 600;
  }
}
.datePicker{
  margin-top: 10px;
  padding: 0 !important;
  div {
    border-radius: 0 !important;  
  }
  input {
    padding: 8px ;
  }
}
.datePicker .MuiOutlinedInput-root {
  border-radius: 0 !important;
}
.activateButton{
  font-size: 16px;
  font-weight: bold;
  color: #1c75bc;
  border: none;
  background-color: unset;
  text-decoration: underline;
  margin-top: 8px;
  width: fit-content;
  &:hover{
    cursor: pointer;
  }
  &:focus{
    outline:none;
  }
}
.criminalCheckBoxChecked{
  color: #fe7976;
}
.downloadTemplate{
  margin-left: 40px;
  text-decoration: unset;
  span{
    font-size: 17px;
    color: #1c75bc;
    text-decoration: underline;
    margin-right: 10px;
  }
}
.noRecord{
  font-family: Nunito;
  font-size: 32px;
  font-weight: 600;
  color: #707070;
  margin: 150px auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  img{
    margin-bottom: 30px;
  }
}
.searchButtonDiv{
  display: flex;
  margin-left: -140px;
  z-index: 100;
  .closeButton{
    margin-right: 8px;
    padding: 0 4px;
    height: 30px;
    margin-top: 3px;
  }
  .noVisibility{
    visibility: hidden;
  }
  .searchButton{
    border: 0;
    background-color: #1c75bc;
    color: white;
    width: 100px;
    font-size: 17px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    height: 35px;
    &:hover{
      cursor: pointer;
    }
    &:focus{
      outline: 0;
    }
  }
}
.fileName{
  margin-left: 24px;
  span {
    font-weight: 700;
  }
}
.responseHeading{
  color: #35343d;
  margin-left: 24px;
  font-size: 20px;
  font-weight: bold;

}
.workerName{
  background-color: unset;
  border: none;
  font-size: 14px;
  color: #1c75bc;
  padding: 0;
  text-decoration: underline;
  &:focus{
    outline: none;
  }
  &:hover{
    cursor: pointer;
  }
}
.summaryText{
  color:#1c75bc;
  font-weight: bold;
  font-size: 18px;
}
table { 
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: separate;
  border-left: 0;
  border-radius: 4px;
  border-spacing: 0px;
}
thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  border-collapse: separate;
}
tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
tr td:last-child{
  text-align: right;
}
th, td {
    padding: 12px ; 
    text-align: left;
    vertical-align: top;
    border-left: 1px solid #ddd !important;    
}
td {
    border-top: 1px solid #ddd;    
}
thead:first-child tr:first-child th:first-child, tbody:first-child tr:first-child td:first-child {
    border-radius: 4px 0 0 0;
}
thead:last-child tr:last-child th:first-child, tbody:last-child tr:last-child td:first-child {
    border-radius: 0 0 0 4px;
}
.criminalRecordContainer{
  display: flex;
  flex-direction: column;
  text-align: center;
  span {
    margin-top: 30px;
    font-size: 21px;
    font-weight: 600;
    padding: 20px 70px 0 70px;
  }
}
.criminalRecordActions{
  .yes{
    height: 40px;
    color: #1c75bc !important;
    border-color: #1c75bc;
    width: 100px;
  }
  .no{
    height: 40px;
    background-color: #1c75bc !important;
    color: #ffffff;
    width: 100px;
    margin-right: 40px;
  }
}