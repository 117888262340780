html, body {
  background-color: #eeeeee;
}
::-webkit-scrollbar {
  width: 0.75em;
}
         
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
  
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
.removeBorders{
  border: 0px !important;
}
.searchInstruction{
  font-family: 'Nunito';
    font-style: italic;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    color: #AFAFAF;
}

.searchInstructionError{
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #F14343;
}
.App {
  margin: 0;
}
.displayNone{
  display: none;
}
.overrideButtonHeightPaymentListing{
  margin-right: 20px ;
  height: 55px !important;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.flexDisplay{
  display: flex;
}
.flexDisplayColumn{
  display: flex;
  flex-direction: column;
}
.criminalRecord{
  background-color: #fcd2d1 !important;
}
.recSortOrderButton{
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
  margin-top: 8px !important;
  height: 35px !important;
  width: 35px;
}
.width200{
  width: 200px;
}
.width270{
  width: 270px;
}
.filterCLoseButton{
  height: 16px;
  width: 16px;
  margin-top: 6px !important;
  left: -30px;
}
.padding10{
  padding: 10px
}
.paddingTop8{
  padding: 8px 0 0 0 !important;
}
.blueColor{
  color: #1c75bc !important;
}

.fontSize16{
  font-size: 16px !important;
}
.fontSize14{
  font-size: 14px !important;
}
.fontSize13{
  font-size: 13px !important;
}
.fontSize12{
  font-size: 12px !important;
}
.fontSize18{
  font-size: 18px !important;
}
.fontSize20{
  font-size: 20px !important;
}
.fontSize24{
  font-size: 24px !important;
}
.fontSize32{
  font-size: 32px !important;
}
.fontSize1_5rem{
  font-size: 1.5rem !important;
}
.fontSize1rem{
  font-size: 1rem !important;
}
.fontSize1_1rem{
  font-size: 1.1rem !important;
}
.fontSize_8rem{
  font-size: 0.8rem !important;
}
.fontWeightBold{
  font-weight: bold !important;
}
.fontWeightBolder{
  font-weight: bolder;
}
.fontWeight600{
  font-weight: 600;
}
.textUnderline{
  text-decoration: underline;
}
.textAlignLeft{
  text-align: left;
}
.textAlignCenter{
  text-align: center;
}
.textAlignRight{
  text-align: right;
}
.padding2percent{
  padding: 2%;
}
.padding8{
  padding: 8px;
}
.paddingVertical6{
  padding: 6px 0;
}
.paddingVertical10{
  padding: 10px 0;
}
.margin20{
  margin: 20px !important;
}
.margin10{
  margin-right:10px  !important;
  margin-left:20px  !important;
}
.marginBottom40{
  margin-bottom: 40px !important;
}
.marginBottom8{
  margin-bottom: 8px !important;
}
.marginRight20{
  margin-right: 20px !important;
}
.marginVertical10{
  margin: 10px 0 !important;
}
.paddingTop16{
  padding-top: 16px;
}
.paddingBottom16{
  padding-bottom: 16px;
}
.color707070{
  color: #707070;
}
.dataButton{
  border-radius: 0px !important;
  background-color: #1c75bc !important;
  color: white !important;
  height: fit-content !important;
}
.dataButton2{
  border-radius: 0px !important;
  background-color: #1c75bc !important;
  color: white !important;
  height: 35px !important;
  margin-top: 8px !important;
}
.dataButton2:disabled{
  background-color:rgba(0, 0, 0, 0.12) !important;
}
.dataButton:disabled{
  background-color:rgba(0, 0, 0, 0.12) !important;
}

.blueColor{
  color: #1c75bc !important;
}
.downloadExcelButton:hover{
  text-decoration: underline !important;
}
.disabledButton{
  background-color: #cccccc !important;
  color: #4f4f4f;
}
.rdt_Table{
  width: max-content;
}
.rdt_TableHeadRow{
  background-color: #1c75bc !important;
}
/* .rdt_TableCol{
  color:white !important;
} */

.rdt_TableCol_Sortable{
  color:white !important;
  min-width: 150px;
}
.rdt_TableCell{
  border: 1px solid #dddddd;
}
.tabContainer{
  border: 1px solid #cfcfcf;
  border-radius: 8px;
  border-top-left-radius: unset;
  padding: 15px;
}
.ReactTable .rt-thead.-header{
  background-color: #1c75bc !important;
  color:white !important;
  font-weight: bold;
}
.ReactTable .rt-thead .rt-tr{
  text-align: left !important;
}

.ReactTable .rt-tbody .rt-tr-group{
  border-bottom: solid 1px #c3c3c3 !important;
}
.ReactTable .rt-tbody .rt-td{
  border-right: 1px solid #c3c3c3 !important;
}
.stickCheckbox{
  position: sticky;
  left: 0;
  filter: drop-shadow(-15px 0px 0px #cfcfcf);
  outline: 1px solid #cfcfcf;
}
.leftAlignLedgerTableRow{
  text-align: left !important;
}
.stickWorkerId{
  position: sticky;
  left: 0;
  border-right: 1px solid #1c75bc;
  outline: 1px solid #cfcfcf;
}
.stickWorkerIdnewMatch{
  position: sticky;
  left: 72px;
  border-right: 1px solid #1c75bc;
  outline: 1px solid #cfcfcf;
}
.put-border{
  outline-style: solid;
  outline-color: #ddd;
  outline-width:1px;
}
.advanceStyle{
  background: #C9E7FF;
    height: 22px;
    font-weight: 500;

    padding: 2px 15px 2px 15px;
    border-radius: 47px;
    max-width: fit-content;
}
.noAdvanceStyle{
  /* background: #C9E7FF; */
    height: 22px;
    font-weight: 500;

    padding: 2px 15px 2px 15px;
    border-radius: 47px;
    max-width: fit-content;
}
.Upper10Margin{
  margin: 10px 0px 0px 0px
}
.Upper20Margin{
  margin: 10px 0px 0px 0px
}
.UpperXMargin{
  margin: 7px 0px 0px 0px
}
.stickWorkerName{
  position: sticky;
  left: 123px;
  
  max-width:200px;
  line-height: 1.4;
  outline: 1px solid #cfcfcf;
}
.stickWorkerNameMatchNew{
  position: sticky;
  left: 73px;
  
  max-width:200px;
  line-height: 1.4;
  outline: 1px solid #cfcfcf;
}
.stickRecruiterName{
  position: sticky;
  left: 57px;
  
  max-width:200px;
  line-height: 1.4;
  outline: 1px solid #cfcfcf;
}
.stickWorkerNameV2{
  position: sticky;
  left: 72px;
  
  max-width:200px;
  line-height: 1.4;
  outline: 1px solid #cfcfcf;
}
.stickWorkerNameDb{
  position: sticky;
  left: 73px;
  
  max-width:200px;
  line-height: 1.4;
  outline: 1px solid #cfcfcf;
}
.stickPaymentNameV2{
  position: sticky;
  left: 72px;
  
  max-width:200px;
  line-height: 1.4;
  outline: 1px solid #cfcfcf;
}

.stickWorkerIdShift{
  position: sticky;
  left: 0px;
  filter: drop-shadow(-15px 0px 0px #cfcfcf);
  max-width:200px;
  line-height: 1.4;
}
.stickWorkerNameShift{
  position: sticky;
  left: 119px;
  
  left: 120px;
  max-width:200px;
  line-height: 1.4;
  outline: 1px solid #cfcfcf;
}
.stickWorkerNameHub{
  position: sticky;
  left: -1px;
  
  max-width:200px;
  line-height: 1.4;
  outline: 1px solid #cfcfcf;
}
.stickApply{
  position: sticky;
  right: 0;
  border-left: 1px solid #cfcfcf;
  border-right: 1px solid #1c75bc;
}
.header{
  top: 0;
  position: sticky;
  z-index: 540;
}
.long-header{
  top: 0;
  min-width: 172px;
  position: sticky;
  z-index: 540;
}
.zIndex1080{
  z-index: 1080 !important;
}
.zIndex1060{
  z-index: 1060 !important;
}
.zIndex540{
  z-index: 540;
}
.zIndex-10{
  z-index: -10;
}
.selectFields{

}
.selectIcon{
  /* display: none !important; */
}
.linkButton{
  color: #1c75bc !important;
  cursor: pointer !important;
  border:none !important ;
  background-color: unset  !important;
  font-size: 14px !important;
  text-transform:uppercase ;
}
.textAlignRight{
  text-align: right;
}
.linkButton:hover{
  text-decoration: underline !important;
}
.linkButton:focus{
  outline: none !important;
}
.marginLeft24{
  margin-left: 24px !important;
}
.linkButton:active{
  margin-top:5px !important;
}
.linkButton:disabled{
  color: #cccccc !important;
}
.fromDate{
  margin: 10px 0 !important;
}
.toDate{
  margin:0 0 10px 0 !important;
}
.borderStd{
  border: 1px solid #cccccc !important;
}
.margin0{
  margin: 0 !important;
}
.marginTop16{
  margin-top: 16px !important;
}
.jobDetail{
  font-family: nunito !important;
  height: 100%;
}
.filterButton{
  border-radius: 5px !important;
  border: 1px solid #cccccc !important;
  color: #4f4f4f !important;
  height: fit-content !important;
  padding: 5px !important;
  text-transform: none !important;
  width: auto;
}
.FilterHeading{
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding-left: 20px;
}
.profilePhotoDiv{
  margin: 0 auto;
}
.profilePhoto{
  border-radius: 50%;
  height: 150px;
  width: 150px;
  margin-top: 50px;
  margin-bottom: 25px;

}

.tabs{
  border-radius: 8px;
}
.tabs .Mui-selected{
  color: #1c75bc;
  text-decoration: underline;
}
.tabButtons{
  font-family: nunito !important;
  max-width: none !important;
  text-transform: none !important;
}
.basicDetails{
  padding: 0 20%;
}
.tabPanel{
  border-radius: 8px;
  background-color: #ffffff;
  margin-left: 30px;
  padding: 40px;
}
.height40{
  height: 40px;
}
.blueColorBorder{
  border: 1px solid #1c75bc !important;
  color: #1c75bc !important;
  background-color: rgba(28, 117, 188, 0.22) !important;

}
.greyColorBorder{ 
  border: 1px solid #cccccc !important;
  color: #333333 !important;

}
.statusButtons{
  border-radius: 11px !important;
  text-transform: inherit !important;
}
.hrbpEditSelect{
  width: 240px;
  border-radius:0px !important;
}
.hrbpEditSelect .MuiOutlinedInput-input{
  padding: 5px 5px 5px 10px !important;
}
.hrbpEditDatePicker .MuiOutlinedInput-root{
 
  border-radius:0px !important;
}
.hrbpEditDatePicker .MuiIconButton-root{
 
  padding:0px;
}
.hrbpEditInput {
  font-family: Nunito;
  width: 230px;
  height: 26px;
  padding-left: 5px;
  border: 1px solid #cccccc;
  font-size: 16px;
}
.blueButtonOutlined{
  color: #1c75bc !important;
  border: 1px solid #1c75bc !important;
}
.blueButtonContained{
  background-color: #1c75bc !important;
  color: #ffffff !important;
}
.nameLink{
  color: #1c75bc;
}
.invoiceLink{
  color: #1c75bc;
  text-decoration: none !important;font-weight: 600;
}