.root {
  font-family: Nunito, serif;
  box-sizing: border-box;
  text-align: center;
}
.heading{
  color: #1c75bc;
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 40px !important;
}
.content{
  font-size: 20px;
  font-weight: 600;
  
}
.navBarStyle{
  height: 80px ;
  background-color: #1c75bc;
  text-align: center;
}
.resendCodeContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span{
    width: 65%;
  }
  button{
    width: 150px ;
    padding: 10px 0;
    background-color: #1c75bc;
    color: #ffffff;
    &:hover{
      background-color: #1463a3;
    }
  }
}
.formParent{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 40px;
}
.form{
  display: flex;
  flex-direction: column;
  width: 40%;
  text-align: center;
}
.marginBottom24{
  margin-bottom: 24px !important;
  
}
.marginTop40{
  margin-top: 40px !important;
  
}
.changePasswordButton{
  background-color:#1c75bc !important;
  color: #ffffff !important;
}
.changePasswordButton:hover{
  background-color:#1463a3 !important;
}
.TextField{
  background-color: #ffffff;
}
.borderColor .Mui-focused{

  outline-color: green !important;
}
.formElement{

}
.formVerification{
  display: flex;
  flex-direction: column;
}