.marginTop-20{
    margin-top: -20px !important;
}
.marginTop20{
    margin-top: 20px !important;
}
.marginTop13{
    margin-top: 13px !important;
}
.filterSlider .MuiSlider-valueLabel span span{
    color: #ffffff !important;
}
.marginTop-10{
    margin-top: -10px !important;
}
.marginLeft-0{
    margin-left: 0px !important;
}
.displayFlex{
    display: flex;
}
.redColor{
    color: #d9534f;
}
.greenColor{
    color: #5cb85c;
}
.blackColor{
    color: black;
}
.marginTop40{
    margin-top: 40px !important;
}
.marginBottom-80{
    margin-bottom: -80px !important;
}
.paddingLeft20only{
    padding: 0 0 0 20px !important;
}